jQuery(document).ready(() => {
  function getLoginsize() {
    let getCarouselWidth,
      getCarouselHeight;
    getCarouselWidth = jQuery('.carousel-inner .item.active .carouselImg img').width();
    getCarouselHeight = jQuery('.carousel-inner .item.active .carouselImg img').height();
    jQuery('.carousel-wrapper').css({ width: getCarouselWidth, height: getCarouselHeight });
  }

  const closeButton = document.querySelector('.validationErrors .close');
  if (closeButton) {
    closeButton.addEventListener('click', function () {
      this.closest('.message').classList.add('hidden');
    });
  }
  jQuery('.js-checkbox').checkbox();

  getLoginsize();
  function winResizeLogin() {
    jQuery(window).resize(() => {
      getLoginsize();
    });
  }
  winResizeLogin();
  return false;
});
